import request from '../../utils/request'


//获取问卷列表
export function getList(data: any){
  return request({
    method:'GET',
    type:'params',
    url:'manage/physiqueQuestionnaire/pageList',
    data:data
  })
}
//获取单条问卷明细
export function getListOne(data: any){
  return request({
    method:'GET',
    type:'params',
    url:'manage/physiqueQuestionnaire/detail',
    data:data
  })
}